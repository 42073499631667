<template>
  <div>
    <!-- Filters -->
    <!--    table-->

    <b-overlay
      id="overlay-bg-product-list"
      class="custom-overlay-icon-top"
      :show="loading"
      variant="white"
      opacity=".5"
      blur="2px"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-0 pb-0"
      >
        <div class="py-1 px-2 d-flex justify-content-between">
          <div v-html="'Tìm thấy ' + (totalProducts || 0) + ' kết quả'" />
          <table-column-toggle
            id="refProductListTable"
            :table-columns="tableColumns"
            @update-columns-show="updateTableColumns"
          />
        </div>
        <b-table
          ref="refProductListTable"
          class="position-relative custom-head thead-no-wrap line-height-12 font-small-4"
          responsive
          hover
          primary-key="id"
          show-empty
          small
          empty-text="Không có kết quả nào"
          :items="userClientList"
          :fields="tableColumns"
          @row-clicked="rowClicked"
        >
          <template #cell(id)="data">
            <span class="d-block font-weight-bold text-primary">{{ data.item.id }}</span>
          </template>
        </b-table>

        <div class="mb-3">
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start mb-1 mb-md-0"
            >
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>mỗi trang</label>
            </b-col>

            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalProducts"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BAvatar,
  BCard,
  BCol,
  BMediaAside,
  BPagination,
  BRow,
  BTable,
  VBTooltip,
  BBadge,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import reportStoreModule from '@/views/report/reportStoreModule'
import TableColumnToggle from '@core/components/TableColumnToggle.vue'
import { subject } from '@casl/ability'

import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import useUserClientList from './useUserClientList'

export default {
  components: {
    BCard,
    BTable,
    TableColumnToggle,
    BPagination,
    vSelect,
    BRow,
    BCol,
    BOverlay,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      loading: false,
      subject,
    }
  },
  computed: {
    Vietnamese() {
      return Vietnamese
    },
  },
  watch: {
    userClientList() {
      setTimeout(() => {
        this.$refs.refProductListTable.refresh()
      }, 200)
    },
  },
  created() {
    // this.filter.product_type = 'townhouse'
    this.fetchReportUserClient().finally(() => {
      this.loading = false
    })
    // waiting for watching done
    setTimeout(() => {
      this.created = true
    }, 500)
  },
  mounted() {
    window.__reportClient = this
  },
  methods: {
    gotoProductDetail({ id }) {
      const route = this.$router.resolve({
        name: 'report-client-detail',
        params: { id },
      })
      window.open(route.href, this.$store.state.app.isDev ? '_self' : '')
    },
    handleSearch() {
      this.$router.replace({
        query: {
          filter: JSON.stringify(this.filter || ''),
        },
      })
      this.fetchProducts().finally(() => {
        this.loading = false
      })
    },
    updateTableColumns(tableColumns) {
      this.tableColumns = tableColumns
    },
    rowClicked(row) {
      this.gotoProductDetail(row)
    },
  },
  setup() {
    const REPORT_STORE_MODULE_NAME = 'report'
    if (!store.hasModule(REPORT_STORE_MODULE_NAME)) store.registerModule(REPORT_STORE_MODULE_NAME, reportStoreModule)
    onUnmounted(() => {
      if (store.hasModule(REPORT_STORE_MODULE_NAME)) store.unregisterModule(REPORT_STORE_MODULE_NAME)
    })

    return {
      ...useUserClientList(),
    }
  },
}
</script>

  <style lang="scss">
  @import "src/@core/scss/vue/libs/vue-flatpicker.scss";
  .per-page-selector {
    width: 90px;
  }

  .custom-head th {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .table .feather {
    min-width: 1rem;
  }

  .product-badge {
    position: absolute;
    right: 0;
    font-size: 0.7rem !important;

    &.hot {
      top: 0.25rem;
    }

    &.corner {
      bottom: 0.25rem;
    }

    &.hidden-phone {
      top: 0.25rem;
    }
  }

  .product-actions-wrapper {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;

    > * {
      padding: 0.25rem !important;
    }
  }
  </style>
